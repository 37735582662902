import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import aboutStyle from "assets/jss/material-kit-react/views/componentsSections/aboutStyle.jsx";

class SectionAbout extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
              <h2>Über uns</h2>
              <h4>
                Egal ob Motorrad, Auto oder Traktor- als Meisterbetrieb
                verfügen wir über jahrzehntelange Erfahrung, das Fachwissen
                und die professionellen Maschinen um Ihren Motor wieder 
                zum Leben zu erwecken.
              </h4>
        </div>
      </div>
    );
  }
}

export default withStyles(aboutStyle)(SectionAbout);
