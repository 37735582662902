const noCookiesAllowedStyle = {
  noCookiesAllowed: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(90deg, rgba(54,83,231,1) 0%, rgba(6,38,199,1) 100%)",
    padding: "1rem",
    borderRadius: "3px",
    color: "white",
    textAlign: "center"
  },
  maps: {
    width: "100%",
    height: "300px",
  },
  yt: {
    width: "560px",
    height: "315px"
  },
  banner: {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100vw",
    background: "rgba(0,0,0,.5)",
    zIndex: "20",
    padding: "2rem 2rem 0 2rem",
    color: "white",
    textAlign: "center",
    boxSizing: "border-box"
  }
};

export default noCookiesAllowedStyle;
