import { container } from "assets/jss/material-kit-react.jsx";

const contactStyle = {
    nopadding: {
      padding: "0 !import"
    },
    textcenter: {
      textAlign: "center"
    },
    logodiv: {
    background: "linear-gradient(90deg, rgba(54,83,231,1) 0%, rgba(6,38,199,1) 100%);",
    margin: "50px 0 0 0",
    width: "100%",
    height: "200px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    borderRadius: "3px"
  },
  logo: {
    maxWidth: "80%",
    minWidth: "50px",
    height: "auto"
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    }
  },
  address: {
    textAlign: "center",
    marginTop: "5px"
  },
  section: {
    overflow: "hidden",
    padding: "100px 0 200px 0",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
  },
    alignright: {
    float: "right"
  },
    alignleft: {
    float: "left"
  },
  clear: {
    clear: "both"
  },
  container,
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px"
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  },
  socialLine: {
    marginTop: "5px",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: "#495057"
  }
};

export default contactStyle;
