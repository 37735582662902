const leistungenStyle = {
      alignright: {
    float: "right",
    margin: "0 0 0 40px"
  },
    alignleft: {
    float: "left"
  },
  clear: {
    clear: "both",
    borderBottom: "1px solid grey"
  },
  card: {
    border: "0",
    borderRadius: "6px",
    color: "#fff",
    background: "linear-gradient(90deg, rgba(54,83,231,0.8) 0%, rgba(6,38,199,0.8) 100%);",
    height: "300px",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    display: "flex",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    transition: "all 300ms linear",
    alignItems: "center",
    justifyContent: "center",
  },
  pointer: {
        "&:hover": {
      cursor: "pointer"
    }
  },
  container: {
    marginBottom: "60px",
    marginTop: "5em",
    textAlign: "center"
  },
  h3margin: {
    margin: "0px 10px 0px 10px"
  },
  h2margin: {
    margin: "0px 0px 40px 0px"
  },
   h2marginlogos: {
    margin: "0"
  },
   h3marginlogos: {
    margin: "0px 0px 40px 0px"
  },
  responsive: {
    width: "100%",
    maxWidth: "250px",
    height: "auto",
    margin: "5px"
  }
};

export default leistungenStyle;
