import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import Button from "components/CustomButtons/Button.jsx";
import impStyles from "assets/jss/material-kit-react/views/componentsSections/impStyles.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class SectionImp extends React.Component {
  anchorElLeft = null;
  anchorElTop = null;
  anchorElBottom = null;
  anchorElRight = null;
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false
    };
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true
    });
  }
  render() {
    const { classes } = this.props;
    return (
          <div>
                  <div
                    className={classes.block}
                    onClick={() => this.handleClickOpen("classicModal")}
                  >
                    Impressum
                  </div>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal
                    }}
                    open={this.state.classicModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("classicModal")}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <h4 className={classes.modalTitle}>Impressum</h4>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <p>
                        Motoren-Technik-Schröter<br />

                        Sven Schröter, Einzelunternehmer<br />

                        Grüner Weg 6<br />

                        23936 Grevesmühlen<br />

                        Telefon: 03881 / 75 83 62<br />

                        Telefax: 03881 / 75 84 03<br />

                        Mobil: 0160 / 91 41 38 26<br />

                        E-Mail: info@motoren-technik-schroeter.de<br />

                        Steueridentifikationsnummer: 080/271/03937<br />

                        Umsatzsteueridentifikationsnummer: DE 247129574<br />

                        www.motoren-technik-schroeter.de
                      </p>
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                      <Button
                        onClick={() => this.handleClose("classicModal")}
                        color="danger"
                        simple
                      >
                        Schließen
                      </Button>
                    </DialogActions>
                  </Dialog>
                  </div>
    );
  }
}

export default withStyles(impStyles)(SectionImp);