import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import BildStyle from "assets/jss/material-kit-react/views/componentsSections/bildStyle.jsx";


class SectionBild extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
      </div>
    );
}
}

export default withStyles(BildStyle)(SectionBild);