import React, {Fragment, useEffect, useState} from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import ScrollableAnchor, {configureAnchors} from "react-scrollable-anchor";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionAbout from "./Sections/SectionAbout.jsx";
import SectionBild from "./Sections/SectionBild.jsx";
import SectionContact from "./Sections/SectionContact.jsx";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import SwiperLeistungen from "../../components/Swiper leistungen/swiperleistungen";
import CookieConsent from "../../components/NoCookiesAllowed/CookieConsent";
import useCookies from "react-cookie/cjs/useCookies";
import SectionFarmtrac from "./Sections/SectionFarmtrac";

configureAnchors({offset: -60, scrollDuration: 200})

function Components(props) {
    const {classes, ...rest} = props;
    const [cookiesAllowed, setCookiesAllowed] = useState(false);
    const [showBanner, setShowBanner] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        if (cookies['acceptedCookies']) {
            setCookiesAllowed(true);
            setShowBanner(false);
        }
    })

    const acceptCookies = () => {
        setCookiesAllowed(true);
        setShowBanner(false);
        setCookie("acceptedCookies", true);
    }

    const denyCookies = () => {
        setCookiesAllowed(false);
        setShowBanner(false);
        removeCookie("acceptedCookies");
    }

    return (
        <Fragment>
            <div>
                <Header
                    rightLinks={<HeaderLinks/>}
                    fixed
                    color="transparent"
                    changeColorOnScroll={{
                        height: 400,
                        color: "dark"
                    }}
                    {...rest}
                />
                <Parallax image={require("assets/img/bg4.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem>
                                <div className={classes.brand}>
                                    <h1 className={classes.title}>Motoreninstandsetzung<br/> aller Art</h1>
                                    <h3 className={classes.subtitle}>
                                        Ihre Meisterwerkstatt des Vertrauens
                                    </h3>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <ScrollableAnchor id={'farmtrac'}>
                        <SectionFarmtrac />
                    </ScrollableAnchor>
                    <ScrollableAnchor id={'leistungen'}>
                        <SwiperLeistungen cookiesAllowed={cookiesAllowed} openCookieBannerCallback={() => setShowBanner(true)}/>
                    </ScrollableAnchor>
                    <SectionBild/>
                    <ScrollableAnchor id={'about'}>
                        <div className={classes.section1}>
                            <GridContainer justify="center">
                                <GridItem xs={10} sm={10} md={5} className={classes.marginAuto}>
                                    <SectionAbout/>
                                </GridItem>
                                <GridItem xs={10} sm={10} md={5} className={classes.marginAuto}>
                                    <SectionCarousel/>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </ScrollableAnchor>
                    <ScrollableAnchor id={'contact'}>
                        <SectionContact cookiesAllowed={cookiesAllowed} openCookieBannerCallback={() => setShowBanner(true)}/>
                    </ScrollableAnchor>
                </div>
                <Footer/>
            </div>
            {showBanner ? <CookieConsent acceptCallback={acceptCookies} denyCallback={denyCookies} /> : ""}
        </Fragment>
    );
}

export default withStyles(componentsStyle)(Components);
