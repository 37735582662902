import React, {Fragment, useEffect} from 'react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import farmtrac1 from "../../../assets/img/farmtrac1.JPG";
import farmtrac2 from "../../../assets/img/farmtrac2.JPG";
import farmtrac3 from "../../../assets/img/farmtrac3.JPG";
import farmtrac4 from "../../../assets/img/farmtrac4.JPG";
import farmtracLogo from "../../../assets/img/logos/farmtrac_logo.png";
import AOS from 'aos';
import "aos/dist/aos.css";
import farmtracStyle from "../../../assets/jss/material-kit-react/components/farmtracStyle";
import Carousel from "react-slick";

function SectionFarmtrac({classes}) {

    useEffect(() => {
        AOS.init();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <Fragment>
            <div className={classes.farmtracContainer}>
                <Carousel {...settings} className={classes.farmtrac1}>
                    <img src={farmtrac1} data-aos={"fade-right"} data-aos-duration="1500" className={"slick-image"} alt={"Bild eines Farmtrac Traktors"}/>
                    <img src={farmtrac2} data-aos={"fade-right"} data-aos-duration="1500" className={"slick-image"} alt={"Bild eines Farmtrac Traktors"}/>
                    <img src={farmtrac3} data-aos={"fade-right"} data-aos-duration="1500" className={"slick-image"} alt={"Bild eines Farmtrac Traktors"}/>
                    <img src={farmtrac4} data-aos={"fade-right"} data-aos-duration="1500" className={"slick-image"} alt={"Bild eines Farmtrac Traktors"}/>
                </Carousel>
                <div className={classes.farmtracInfos} data-aos={"fade-up"} data-aos-duration="1500">
                    <img src={farmtracLogo} data-aos={"fade-left"} data-aos-duration="1500" className={classes.farmtracLogo} alt={"Farmtrac Logo"}/>
                    <p className={classes.farmtracInfoText}>
                        Wir sind Farmtrac Vertragshändler! <br /> Die Preis-Leistungs-Wunder mit zuverlässigen Mitsubishi Motoren sind nun bei uns erhältlich.
                        Neben einer hydrostatischer Lenkung und Doppelpedalen, einer hinteren Zapfwelle und einer leistungsstarken Dreipunktaufnahme bieten
                        diese Traktoren auch gehobene Extras wie z.B. einen Tempomat, damit für alle Arbeiten im Kommunaldienst, auf Pferdehöfen oder Privat
                        keine Wünsche offen bleiben. <br /> Im Gegensatz zu anderen Traktoren dieser Preiskategorie gibt es eine sichere Ersatzteilversorgung und
                        eine Vielzahl von Konfigurationen, wie z.B. Frontlader und Kabinen. Außerdem können wir Ihnen als Fachwerkstatt den bestmöglichen
                        Service in Sachen Inspektion und Wartung bieten. Überzeugen Sie sich selbst und besuchen Sie uns!
                    </p>
                    <div className={classes.moreInfos}>
                        <p className={classes.farmtracInfoText}>Weitere Informationen finden Sie unter: <a href={"https://www.farmtrac-deutschland.de/"} target={"_blank"}>www.Farmtrac-Deutschland.de</a></p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withStyles(farmtracStyle)(SectionFarmtrac);