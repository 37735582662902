import image from "assets/img/zwischenbild.jpg";

const bildStyle = {
  section: {
    minHeight: "500px",
    maxHeight: "1600px",
    overflow: "hidden",
    padding: "70px 0",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    backgroundImage: "url(" + image + ")"
  }
};

export default bildStyle;