import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import noCookiesAllowedStyle from "../../assets/jss/material-kit-react/components/noCookiesAllowedStyle";
import classNames from "classnames";
import Button from "../CustomButtons/Button";

const NoCookiesAllowed = ({classes, whatsBlocked, openCookieBannerCallback}) => {

    return <div className={classNames({
        [classes.noCookiesAllowed]: true,
        [classes.maps]: true,
    })}>
        <h4>Cookies sind deaktiviert. {whatsBlocked}</h4>
        <Button simple color="white" size="lg" onClick={openCookieBannerCallback}>Cookie Einstellungen öffnen</Button>
    </div>
}

export default withStyles(noCookiesAllowedStyle)(NoCookiesAllowed);