import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Datenschutz from "components/ImpAGB/Datenschutz.jsx"
import axios from 'axios';

import mtslogo from "assets/img/headerlogo.png";

import contactStyle from "assets/jss/material-kit-react/views/componentsSections/contactStyle.jsx";
import NoCookiesAllowed from "../../../components/NoCookiesAllowed/NoCookiesAllowed";

const API_PATH = 'http://motoren-technik-schroeter.de/index.php';

class SectionContact extends React.Component {
  handleFormSubmit = e => {
  e.preventDefault();
  axios({
    method: 'post',
    url: `${API_PATH}`,
    headers: { 'content-type': 'application/json' },
    data: this.state
  })
    .then(result => {
      this.setState({
        mailSent: result.data.sent
      })
    })
    .catch(error => this.setState({ error: error.message }));
};

  constructor(props) {
  super(props);
  this.state = {
    name: '',
    email: '',
    message: '',
    mailSent: false,
    error: null
   }
  }
  render() {
    const { classes, cookiesAllowed, openCookieBannerCallback } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card contact>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Öffnungszeiten</h4>
                  </CardHeader>
                  <CardBody>
                    <div className={classes.clear}>
                      <p className={classes.alignleft}><strong>Montag: </strong></p><p className={classes.alignright}>8:00 - 17:30</p>
                    </div>
                    <div className={classes.clear}>
                      <p className={classes.alignleft}><strong>Dienstag: </strong></p><p className={classes.alignright}>8:00 - 17:30</p>
                    </div>
                    <div className={classes.clear}>
                      <p className={classes.alignleft}><strong>Mittwoch: </strong></p><p className={classes.alignright}>8:00 - 17:30</p>
                    </div>
                    <div className={classes.clear}>
                     <p className={classes.alignleft}><strong>Donnerstag: </strong></p><p className={classes.alignright}>8:00 - 17:30</p>
                    </div>
                    <div className={classes.clear}>
                      <p className={classes.alignleft}><strong>Freitag: </strong></p><p className={classes.alignright}>8:00 - 17:30</p>
                    </div>
                    <div className={classes.clear}>
                      <p className={classes.alignleft}><strong>Samstag: </strong></p><p className={classes.alignright}>Nur nach <strong>vorheriger</strong> Vereinbarung</p>
                    </div>
                    <div className={classes.logodiv}>
                      <img src={mtslogo} alt="mts logo" className={classes.logo}/>
                    </div>
                  </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <Card contact>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Addresse</h4>
                  </CardHeader>
                  <CardBody>
                    {cookiesAllowed ? <iframe
                        title="Google Maps Anfahrt"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2353.049757730056!2d11.194961315994131!3d53.85976314383057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47adeba259bc7f25%3A0xc5daf5edd662738d!2sMotoren+Technik+Schr%C3%B6ter!5e0!3m2!1sde!2sde!4v1556262807387!5m2!1sde!2sde"
                        width="100%"
                        height="300"
                        frameBorder="0"
                        allowFullScreen>
                    </iframe> : <NoCookiesAllowed whatsBlocked={"Aktivieren Sie Cookies, um die Google Maps Einbindung zu sehen."} openCookieBannerCallback={openCookieBannerCallback}/>}
                    <div className={classes.address}>
                      <strong>
                        Grüner Weg 6<br />
                        23936 Grevesmühlen<br />
                        +49 03881/ 75 83 62<br />
                        info@motoren-technik-schroeter.de<br />
                      </strong>
                    </div>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                  </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <Card contact>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Kontakt</h4>
                    <div className={classes.socialLine}>
                      <Button
                        href="https://www.facebook.com/Motoren-Technik-Schröter-1455422948073547/"
                        target="_blank"
                        color="transparent"
                        contact={true}
                      >
                        <i
                          className={classes.socialIcons + " fab fa-facebook"}
                        />
                      </Button>
                      <Button
                        href="mailto:info@motoren-technik-schroeter.de"
                        target="_blank"
                        color="transparent"
                        contact={true}
                      >
                        <i
                          className={
                            classes.socialIcons + " fas fa-envelope-square"
                          }
                        />
                      </Button>
                    </div>
                  </CardHeader>
                  <p className={classes.textcenter}>Oder über das Formular:</p>
                  <form className={classes.form} action="mail.php" method="post">
                    <CardBody>
                      <CustomInput
                        labelText="Name..."
                        id="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "name",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <People className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        multiline={false}
                        required={true}
                      />
                      <CustomInput
                        labelText="Deine Email..."
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          name: "email",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        multiline={false}
                      />
                      <CustomInput
                        labelText="Nachricht..."
                        id="nachricht"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "nachricht",
                          required: true,
                        }}
                        multiline={true}
                      />
                    </CardBody>
                    <div className={classes.textcenter}><Datenschutz title="Datenschutzerklärung akzeptieren" checkbox="true"/></div>
                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="primary" size="lg" type="submit" name="submit">
                        Absenden
                      </Button>
                    </CardFooter>
                  </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(contactStyle)(SectionContact);

