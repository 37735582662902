const farmtracStyle = theme => ({
    farmtrac1: {
        width: "40%",
        height: "100%",
        marginRight: "1rem",
        marginTop: "4rem",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
            marginRight: "0"
        }
    },
    farmtracContainer: {
        width: "100%",
        padding: "5rem 10rem",
        display: "flex",
        justifyContent: "space-evenly",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            alignItems: "center",
            padding: "2rem 1rem"
        }
    },
    farmtracInfos: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "40%",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        }
    },
    farmtracInfoText: {
        fontSize: "1.125rem",
        lineHeight: "1.5rem"
    },
    farmtracLogo: {
        marginBottom: "2rem",
        width: "80%",
        height: "auto",
    },
    moreInfos: {
        display: "flex"
    }
})

export default farmtracStyle;