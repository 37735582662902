import { container } from "assets/jss/material-kit-react";

const aboutStyle = {
  section: {
    padding: "25% 0"
  },
  container: {
    ...container,
    textAlign: "center !important"
  }
};

export default aboutStyle;
