import React from 'react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {Navigation} from "swiper/dist/js/swiper.esm";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Button from "components/CustomButtons/Button.jsx";

// Bilder
import logoks from "assets/img/logos/logo-kolbenschmidt.png";
import logomahle from "assets/img/logos/logo-mahle.png";
import logogoetze from "assets/img/logos/logo-goetze.png";
import logochampion from "assets/img/logos/logo-champion.png";
import logofederal from "assets/img/logos/logo-federal.png";
import logovictor from "assets/img/logos/logo-victor.png";
import logotrw from "assets/img/logos/logo-trw.png";
import logoglyco from "assets/img/logos/logo-glyco.png";
import logonural from "assets/img/logos/logo-nural.png";
import logopayen from "assets/img/logos/logo-payen.png";
import logowiseco from "assets/img/logos/logo-wiseco.png";
import logoelring from "assets/img/logos/logo-elring.png";

import leistungenStyle from "assets/jss/material-kit-react/components/leistungenStyle.jsx";
import Swiper from 'react-id-swiper';
import NoCookiesAllowed from "../NoCookiesAllowed/NoCookiesAllowed";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class SwiperLeistungen extends React.Component {
    anchorElLeft = null;
    anchorElTop = null;

    constructor(props) {
        super(props);
        this.state = {
            classicModal: false,
            classicModal2: false,
            classicModal3: false,
            classicModal4: false,
            classicModal5: false,
            classicModal6: false,
            openLeft: false,
            openTop: false,
            openBottom: false,
            openRight: false
        };
    }

    handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x);
    }

    handleClose(modal) {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    handleClosePopover(state) {
        this.setState({
            [state]: false
        });
    }

    render() {
        const {classes, cookiesAllowed, openCookieBannerCallback} = this.props;
        const params = {
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            loop: true,
            // Default parameters
            slidesPerView: 4,
            spaceBetween: 40,
            slidesPerGroup: 2,
            speed: 800,
            loopFillGroupWithBlank: true,
            // Responsive breakpoints
            breakpoints: {
                // when window width is <= 480px
                650: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    slidesPerGroup: 1
                },
                // when window width is <= 640px
                950: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                    slidesPerGroup: 1
                },
                1100: {
                    slidesPerView: 3,
                    spaceBetween: 35,
                    slidesPerGroup: 2
                }
            }
        }
        return (
            <GridContainer justify="center">
                <GridItem xs={10} sm={10} md={10} className={classes.marginAuto}>
                    <div className={classes.container}>
                        <h2 className={classes.h2margin}>Unsere Leistungen</h2>
                        <Swiper {...params}>

                            <div
                                className={`${classes.card} ${classes.pointer}`}
                                onClick={() => this.handleClickOpen("classicModal6")}
                            >
                                <h3 className={classes.h3margin}>Preisliste<br/></h3></div>
                            <Dialog
                                classes={{
                                    root: classes.center,
                                    paper: classes.modal
                                }}
                                open={this.state.classicModal6}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => this.handleClose("classicModal6")}
                                aria-labelledby="classic-modal-slide-title"
                                aria-describedby="classic-modal-slide-description"
                            >
                                <DialogTitle
                                    id="classic-modal-slide-title6"
                                    disableTypography
                                    className={classes.modalHeader}
                                >
                                    <h4 className={classes.modalTitle}>Preisliste</h4>
                                </DialogTitle>
                                <DialogContent
                                    id="classic-modal-slide-description6"
                                    className={classes.modalBody}
                                >
                                    <p className={classes.alignright}>Preis zzgl. <br/> Mehrwertsteuer</p>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Stundenverrechnungssatz</strong></p><p className={classes.alignright}>48,00€</p>
                                    </div>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Zylinderkopf planen (4 Zyl.)</strong></p><p className={classes.alignright}>35,00€</p>
                                    </div>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Rissprüfung Zylinderkopf (4 Zyl.)</strong></p><p className={classes.alignright}>65,00€</p>
                                    </div>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Ventilsitz erneuern</strong></p><p className={classes.alignright}>9,80€</p>
                                    </div>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Ventilsitz neu fräsen</strong></p><p className={classes.alignright}>3,70€</p>
                                    </div>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Ventil schleifen</strong></p><p className={classes.alignright}>3,20€</p>
                                    </div>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Ventilführung erneuern</strong></p><p className={classes.alignright}>7,00€</p>
                                    </div>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Kurbelwelle schleifen (pro Lagerstelle)</strong></p><p className={classes.alignright}>52,00€</p>
                                    </div>
                                    <div className={classes.clear}>
                                        <p className={classes.alignleft}><strong>Zylinder bohren und hohnen (pro Zylinder)</strong></p><p className={classes.alignright}>45,00€</p>
                                    </div>


                                </DialogContent>
                                <DialogActions className={classes.modalFooter}>
                                    <Button
                                        onClick={() => this.handleClose("classicModal6")}
                                        color="danger"
                                        simple
                                    >
                                        Schließen
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <div
                                className={`${classes.card} ${classes.pointer}`}
                                onClick={() => this.handleClickOpen("classicModal")}
                            >
                                <h3 className={classes.h3margin}>Bohren und Hohnen des Motorrumpfes<br/><i className="fab fa-youtube"/></h3></div>
                            <Dialog
                                classes={{
                                    root: classes.center,
                                    paper: classes.modal
                                }}
                                open={this.state.classicModal}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => this.handleClose("classicModal")}
                                aria-labelledby="classic-modal-slide-title"
                                aria-describedby="classic-modal-slide-description"
                            >
                                <DialogTitle
                                    id="classic-modal-slide-title"
                                    disableTypography
                                    className={classes.modalHeader}
                                >
                                    <h4 className={classes.modalTitle}>Zylinder Hohnen</h4>
                                </DialogTitle>
                                <DialogContent
                                    id="classic-modal-slide-description"
                                    className={classes.modalBody}
                                >
                                    {cookiesAllowed ? (<iframe
                                            title="YT Video Zylinder hohnen"
                                            width="560"
                                            height="315"
                                            src="https://www.youtube.com/embed/o_4EsDNycd4"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope"
                                            allowFullScreen>
                                        </iframe>)
                                        : <NoCookiesAllowed whatsBlocked={"Aktivieren Sie Cookies, um die Youtube Einbindung zu aktivieren und das Video sehen zu können."} openCookieBannerCallback={openCookieBannerCallback} />}
                                </DialogContent>
                                <DialogActions className={classes.modalFooter}>
                                    <Button
                                        onClick={() => this.handleClose("classicModal")}
                                        color="danger"
                                        simple
                                    >
                                        Schließen
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <div className={classes.card}><h3 className={classes.h3margin}>Vermessung des
                                Triebwerkes</h3></div>

                            <div
                                className={`${classes.card} ${classes.pointer}`}
                                onClick={() => this.handleClickOpen("classicModal2")}
                            >
                                <h3 className={classes.h3margin}>Planen des Motorrumpfes und Zylinderkopfes<br/><i className="fab fa-youtube"/></h3></div>
                            <Dialog
                                classes={{
                                    root: classes.center,
                                    paper: classes.modal
                                }}
                                open={this.state.classicModal2}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => this.handleClose("classicModal2")}
                                aria-labelledby="classic-modal-slide-title"
                                aria-describedby="classic-modal-slide-description"
                            >
                                <DialogTitle
                                    id="classic-modal-slide-title2"
                                    disableTypography
                                    className={classes.modalHeader}
                                >
                                    <h4 className={classes.modalTitle}>Zylinderkopf planen</h4>
                                </DialogTitle>
                                <DialogContent
                                    id="classic-modal-slide-description2"
                                    className={classes.modalBody}
                                >
                                    <p>Der erste Durchlauf beim Planen eines Zylinderkopfes auf unserer Berco SM 1300 M
                                        Maschine</p>
                                    {cookiesAllowed ? <iframe
                                        title="YT Video Zylinderkopf planen"
                                        width="560"
                                        height="315"
                                        src="https://www.youtube.com/embed/TrOPz-9KR_E"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope"
                                        allowFullScreen>
                                    </iframe> : <NoCookiesAllowed whatsBlocked={"Aktivieren Sie Cookies, um die Youtube Einbindung zu aktivieren und das Video sehen zu können."} openCookieBannerCallback={openCookieBannerCallback} />}
                                </DialogContent>
                                <DialogActions className={classes.modalFooter}>
                                    <Button
                                        onClick={() => this.handleClose("classicModal2")}
                                        color="danger"
                                        simple
                                    >
                                        Schließen
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <div className={classes.card}><h3 className={classes.h3margin}>Schleifen der
                                Kurbelwelle</h3></div>

                            <div
                                className={`${classes.card} ${classes.pointer}`}
                                onClick={() => this.handleClickOpen("classicModal3")}
                            >
                                <h3 className={classes.h3margin}>Erneuerung von Sitzringen und Führungen<br/><i className="fab fa-youtube"/></h3></div>
                            <Dialog
                                classes={{
                                    root: classes.center,
                                    paper: classes.modal
                                }}
                                open={this.state.classicModal3}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => this.handleClose("classicModal3")}
                                aria-labelledby="classic-modal-slide-title"
                                aria-describedby="classic-modal-slide-description"
                            >
                                <DialogTitle
                                    id="classic-modal-slide-title3"
                                    disableTypography
                                    className={classes.modalHeader}
                                >
                                    <h4 className={classes.modalTitle}>Ventilsitz fräsen</h4>
                                </DialogTitle>
                                <DialogContent
                                    id="classic-modal-slide-description3"
                                    className={classes.modalBody}
                                >
                                    {cookiesAllowed ? <iframe
                                        title="YT Video Ventilsitz"
                                        width="560"
                                        height="315"
                                        src="https://www.youtube.com/embed/vRoWwIwbcqA"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope"
                                        allowFullScreen>
                                    </iframe> : <NoCookiesAllowed whatsBlocked={"Aktivieren Sie Cookies, um die Youtube Einbindung zu aktivieren und das Video sehen zu können."} openCookieBannerCallback={openCookieBannerCallback} />}
                                </DialogContent>
                                <DialogActions className={classes.modalFooter}>
                                    <Button
                                        onClick={() => this.handleClose("classicModal3")}
                                        color="danger"
                                        simple
                                    >
                                        Schließen
                                    </Button>
                                </DialogActions>
                            </Dialog>


                            <div
                                className={`${classes.card} ${classes.pointer}`}
                                onClick={() => this.handleClickOpen("classicModal4")}
                            >
                                <h3 className={classes.h3margin}>Aufarbeiten und Einschleifen von Ventilen<br/><i className="fab fa-youtube"/></h3></div>
                            <Dialog
                                classes={{
                                    root: classes.center,
                                    paper: classes.modal
                                }}
                                open={this.state.classicModal4}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => this.handleClose("classicModal4")}
                                aria-labelledby="classic-modal-slide-title"
                                aria-describedby="classic-modal-slide-description"
                            >
                                <DialogTitle
                                    id="classic-modal-slide-title4"
                                    disableTypography
                                    className={classes.modalHeader}
                                >
                                    <h4 className={classes.modalTitle}>Ventile schleifen</h4>
                                </DialogTitle>
                                <DialogContent
                                    id="classic-modal-slide-description4"
                                    className={classes.modalBody}
                                >
                                    {cookiesAllowed ? <iframe
                                        title="YT Video Ventil schleifen"
                                        width="560"
                                        height="315"
                                        src="https://www.youtube.com/embed/ZEQrkRX7NBU"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope"
                                        allowFullScreen>
                                    </iframe> : <NoCookiesAllowed whatsBlocked={"Aktivieren Sie Cookies, um die Youtube Einbindung zu aktivieren und das Video sehen zu können."} openCookieBannerCallback={openCookieBannerCallback} />}
                                </DialogContent>
                                <DialogActions className={classes.modalFooter}>
                                    <Button
                                        onClick={() => this.handleClose("classicModal4")}
                                        color="danger"
                                        simple
                                    >
                                        Schließen
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <div className={classes.card}><h3 className={classes.h3margin}>Vermessen und Nacharbeitung
                                der Lager/Lagerstellen</h3></div>
                            <div className={classes.card}><h3 className={classes.h3margin}>Einstellarbeiten am
                                Ventiltrieb</h3></div>

                            <div
                                className={`${classes.card} ${classes.pointer}`}
                                onClick={() => this.handleClickOpen("classicModal5")}
                            >
                                <h3 className={classes.h3margin}>Rissprüfung am Zylinderkopf<br/><i className="fab fa-youtube"/></h3></div>
                            <Dialog
                                classes={{
                                    root: classes.center,
                                    paper: classes.modal
                                }}
                                open={this.state.classicModal5}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => this.handleClose("classicModal5")}
                                aria-labelledby="classic-modal-slide-title"
                                aria-describedby="classic-modal-slide-description"
                            >
                                <DialogTitle
                                    id="classic-modal-slide-title5"
                                    disableTypography
                                    className={classes.modalHeader}
                                >
                                    <h4 className={classes.modalTitle}>Rissprüfung am Zylinderkopf</h4>
                                </DialogTitle>
                                <DialogContent
                                    id="classic-modal-slide-description5"
                                    className={classes.modalBody}
                                >
                                    {cookiesAllowed ? <iframe
                                        title="YT Video Rissprüfung"
                                        width="560"
                                        height="315"
                                        src="https://www.youtube.com/embed/XimaeHPe1sI"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope"
                                        allowFullScreen>
                                    </iframe> : <NoCookiesAllowed whatsBlocked={"Aktivieren Sie Cookies, um die Youtube Einbindung zu aktivieren und das Video sehen zu können."} openCookieBannerCallback={openCookieBannerCallback} />}
                                </DialogContent>
                                <DialogActions className={classes.modalFooter}>
                                    <Button
                                        onClick={() => this.handleClose("classicModal5")}
                                        color="danger"
                                        simple
                                    >
                                        Schließen
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <div className={classes.card}><h3 className={classes.h3margin}>Beschaffung von Ersatz- und
                                Sonderteilen</h3></div>
                            <div className={classes.card}><h3 className={classes.h3margin}>Vieles mehr!</h3></div>
                        </Swiper>
                    </div>

                    <div className={classes.container}>
                        <h2 className={classes.h2marginlogos}>Ersatz- und Sonderteile</h2>
                        <h3 className={classes.h3marginlogos}>Wir arbeiten mit namenhaften Herstellern</h3>
                        <Swiper {...params}>
                            <div className={classes.card}><img src={logoks} alt="Kolbenschmidt"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logomahle} alt="Mahle"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logogoetze} alt="Goetze"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logowiseco} alt="Wiseco"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logochampion} alt="Champion"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logofederal} alt="Federal Mogul"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logovictor} alt="Victor Reinz"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logotrw} alt="TRW automotive"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logoglyco} alt="Glyco"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logonural} alt="Nüral"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logopayen} alt="Payen"
                                                               className={classes.responsive}/></div>
                            <div className={classes.card}><img src={logoelring} alt="Elring"
                                                               className={classes.responsive}/></div>
                        </Swiper>
                    </div>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(leistungenStyle)(SwiperLeistungen);