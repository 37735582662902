import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx";
import vorschau1 from "assets/img/vorschau/vorschau1.jpg";
import vorschau2 from "assets/img/vorschau/vorschau2.jpg";
import vorschau3 from "assets/img/vorschau/vorschau3.jpg";
import vorschau4 from "assets/img/vorschau/vorschau4.jpg";
import vorschau5 from "assets/img/vorschau/vorschau5.jpg";
import vorschau6 from "assets/img/vorschau/vorschau6.jpg";

class SectionCarousel extends React.Component {
  render() {
    const { classes } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
              <Card carousel>
                <Carousel {...settings}>
                  <div>
                    <img
                      src={vorschau1}
                      alt="rissprüfung"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h3 className={classes.shadow}>
                        Rissprüfung
                      </h3>
                    </div>
                  </div>
                  <div>
                    <img
                      src={vorschau2}
                      alt="geplanter Zylinderkopf"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h3 className={classes.shadow}>
                        Geplanter Zylinderkopf
                      </h3>
                    </div>
                  </div>
                  <div>
                    <img
                      src={vorschau3}
                      alt="Schraubenregal"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h3 className={classes.shadow}>
                        Schraubenlager
                      </h3>
                    </div>
                  </div>
                  <div>
                    <img
                      src={vorschau4}
                      alt="Planmaschine"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h3 className={classes.shadow}>
                        Planmaschine Berco SM 1300M
                      </h3>
                    </div>
                  </div>
                  <div>
                    <img
                      src={vorschau5}
                      alt="Ventilsitzfräßmaschine"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h3 className={classes.shadow}>
                        Ventilsitzfräßmaschine Rottler Manualmatic
                      </h3>
                    </div>
                  </div>
                  <div>
                    <img
                      src={vorschau6}
                      alt="Hohnmaschine"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h3 className={classes.shadow}>
                        Bohrwerk
                      </h3>
                    </div>
                  </div>
                </Carousel>
              </Card>
        </div>
      </div>
    );
  }
}

export default withStyles(carouselStyle)(SectionCarousel);
