import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import noCookiesAllowedStyle from "../../assets/jss/material-kit-react/components/noCookiesAllowedStyle";
import classNames from "classnames";
import Button from "../CustomButtons/Button";

const CookieConsent = ({classes, acceptCallback, denyCallback}) => {

    return <div className={classNames({
        [classes.banner]: true
    })}>
        <p>Wir verwenden Cookies, um eine Video- und Kartenfunktion zur Verfügung zur stellen. Dabei
            werden Informationen zu Ihrer Verwendung unserer Website für soziale
            Medien, Werbung und Analysen an unsere Partner weitergegeben. Unsere Partner führen diese Informationen
            möglicherweise mit weiteren Oaten zusammen, die Sie ihnen bereitgestellt haben Oder die sie
            im Rahmen Ihrer Nutzung der Dienste gesammelt haben. Sie geben Einwilligung zu unseren
            Cookies, wenn Sie unsere Webseite weiterhin nutzen.</p>
        <Button simple color="white" size="lg" onClick={acceptCallback}>Akzeptieren</Button>
        <Button simple color="white" size="lg" onClick={denyCallback}>Ablehnen</Button>
    </div>
}

export default withStyles(noCookiesAllowedStyle)(CookieConsent);