import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import Button from "components/CustomButtons/Button.jsx";
import agbStyles from "assets/jss/material-kit-react/views/componentsSections/agbStyles.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class SectionAGB extends React.Component {
  anchorElLeft = null;
  anchorElTop = null;
  anchorElBottom = null;
  anchorElRight = null;
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false
    };
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true
    });
  }
  render() {
    const { classes } = this.props;
    return (
          <div>
                  <div
                    className={classes.block}
                    onClick={() => this.handleClickOpen("classicModal")}
                  >
                    AGB
                  </div>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal
                    }}
                    open={this.state.classicModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("classicModal")}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <h4 className={classes.modalTitle}>AGB</h4>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      
                        <h3>1. Anerkennung der Lieferungs- und Zahlungsbedingungen</h3>
<p>Für die Durchführung unserer Lieferungen und Leistungen für Motoren, Baugruppen oder Einzelteile (nachstehend "Vertragsgegenstand") gelten die nachstehenden Bedingungen. Andere oder entgegenstehende Bedingungen, z.B. Einkaufsbedingungen des Auftraggebers, gelten auch dann nicht, wenn wir ihnen nicht ausdrücklich widersprechen, es sei denn, wir hätten ihnen schriftlich zugestimmt.</p>


<h3>2. Angebote - Kostenvoranschläge</h3>
<p>Sofern der Kunde Vollkaufmann ist, sind Angebote freibleibend, soweit nichts anderes schriftlich vereinbart ist. Unter der gleichen Voraussetzung, dass der Kunde Vollkaufmann ist, werden die zwecks Abgabe eines Kostenvoranschlags gemachten Leistungen und Lieferungen besonderer Art, wie insbesondere Reisen und Demontagearbeiten, dem Auftraggeber gesondert berechnet, und zwar auch dann, wenn es nicht oder nur in abgeänderter Form zur Ausführung von Instandsetzungsarbeiten kommt. Wünscht der Kunde, gleichgültig ob Vollkaufmann oder nicht, einen verbindlichen Kostenvoranschlag, so wird dieser schriftlich erstellt. Darin werden die jeweiligen Arbeiten und Teile bzw. Liefergegenstände im Einzelnen aufgelistet und mit dem jeweiligen Preis versehen. Der Auftragnehmer ist an den erstellten verbindlichen Kostenvoranschlag bis zum Ablauf von 21 Tagen nach seiner Abgabe gebunden. Bei unverbindlichen Kostenvoranschlägen gelten Abweichungen von + 10 % als statthaft. Zu weitergehenden Überschreitungen holt der Auftragnehmer unverzüglich vor Durchführung weiterer Arbeiten die Zustimmung des Auftraggebers ein. Dem Auftraggeber steht jedoch in diesem Falle ein Kündigungsrecht zu. Wenn dies im Einzelfall vereinbart ist, kann der Auftragnehmer dem Auftraggeber für die Erstellung eines Kostenvoranschlages erbrachte Leistungen berechnen. Wenn jedoch aufgrund des Kostenvoranschlages ein Auftrag erteilt wird, werden für den Kostenvoranschlag berechnete Beträge mit der Rechnung für den Auftrag verrechnet. Preise im Kostenvoranschlag werden jeweils netto angegeben, im nichtkaufmännischen Verkehr zuzüglich gesondert ausgewiesener Mehrwertsteuer.</p>


<h3>3. Aufträge für Instandsetzungen/Reparaturen</h3>
<p>3.1 Der Umfang der jeweiligen Instandsetzungsarbeiten/Reparaturen für den Vertragsgegenstand ist vom Auftraggeber festzulegen. Sofern dies nicht möglich ist, legt der Auftragnehmer den Umfang der durchzuführenden Instandsetzungsarbeiten nach Rücksprache mit dem Auftraggeber fest. In einem Auftragsschein oder in einem Bestätigungsschreiben werden die vereinbarten bzw. mit dem Auftragnehmer abgestimmten zu erbringenden Leistungen bezeichnet. Der voraussichtliche oder der verbindliche Liefertermin wird angegeben. Stellt sich während der Bearbeitung, aber bei Auftragsannahme nicht erkennbar, heraus, dass die Instandsetzung wegen der Mängel des Vertragsgegenstandes unmöglich ist, so ist der Auftragnehmer berechtigt, die bis zu dieser Feststellung geleisteten Arbeiten dem Auftraggeber in Rechnung zu stellen. Soweit sich während der Bearbeitung, aber bei Auftragsannahme nicht erkennbar, herausstellt, dass die Durchführung der Instandsetzungsarbeiten unwirtschaftlich ist, wird der Auftragnehmer den Auftraggeber unverzüglich hiervon verständigen, um eine definitive Entscheidung des Auftraggebers herbeizuführen. Entscheidet sich der Auftraggeber dazu, den Auftrag wegen seiner Unwirtschaftlichkeit nicht durchführen zu lassen, so hat der Auftragnehmer Anspruch auf Abgeltung der bis zu diesem Zeitpunkt geleisteten Arbeiten, einschließlich eines angemessenen Gewinns. Der Auftragnehmer haftet nicht für Fehler, die sich aus Unterlagen, Zeichnungen, Mustern usw. sowie aus Angaben des Auftraggebers ergeben, soweit ihm nicht zuzumuten ist, diese zu erkennen.</p>


<h3>4. Kauf/Tausch</h3>
<p>4.1 Gegenstand der Verpflichtung des Auftragnehmers kann auch die Lieferung eines generalüberholten Vertragsgegenstandes, ggf. gegen Übergabe eines entsprechenden alten Motors, einer Baugruppe oder eines Einzelteils gleicher Type sein. Abweichungen in der Ausführung sind dem Auftragnehmer gestattet, soweit dies dem Auftraggeber zumutbar ist. Vertragsgegenstände des Auftraggebers, die dieser dem Auftragnehmer zum Einbau oder im Wege des Tausches überlässt, dürfen keine Mängel oder sonstigen Fehler aufweisen, die nicht auf natürliche Abnutzung zurückzuführen sind. Insbesondere muss der anzuliefernde Vertragsgegenstand frei von geschweißten oder nichtgeschweißten Brüchen und Rissen sein.<br />

4.2 Für die im Falle des Tausches eines Vertragsgegenstandes zu leistende Entschädigung gelten die jeweiligen gesonderten Vereinbarungen.</p>


<h3>5. Preise und Zahlungen</h3>
<p>5.1 Alle Preise verstehen sich für Lieferungen und Leistungen ab Betrieb des Auftragnehmers; es gelten die jeweiligen Listenpreise zzgl. der gesetzlichen Mehrwertsteuer.<br />

5.2 Die jeweiligen Preise gelten ausschließlich Porto, Fracht und Verpackung. Soweit die Verpackung vom Auftragnehmer beigestellt wird, werden die Selbstkosten berechnet. Beanstandungen oder Reklamationen der erteilten Rechnungen sollen, sofern sich nicht aus den Umständen etwas anderes ergibt, innerhalb von acht Tagen nach Aushändigung der jeweiligen Rechnung erfolgen.<br />

5.3 Die jeweilige Mehrwertsteuer wird in gesetzlicher Höhe hinzugerechnet. Im nichtkaufmännischen Bereich wird der Preis einschließlich gesondert ausgewiesener Mehrwertsteuer angegeben.<br />

5.4 Für Vertragsgegenstände, die im Tausch geliefert werden, ist der vereinbarte Preis davon abhängig, dass diese Hauptteile instandsetzungsfähig sind; nicht mehr instandsetzungsfähige Teile werden nachberechnet. Der Auftraggeber hat in diesem Fall einen Anspruch auf Rückforderung der Teile.<br />

5.5 Zahlungen sind Zug um Zug nach Erhalt der Rechnung - netto - zu leisten, soweit nichts anderes schriftlich vereinbart ist. Ein Abzug von Skonto ist unzulässig. Schecks und Wechsel werden nur nach vorheriger Vereinbarung - zahlungshalber - entgegengenommen, vorbehaltlich rechtzeitiger und ordnungsgemäßer Gutschrift. Anfallende Inkasso- und Diskontspesen werden weiterberechnet.<br />

5.6 Aufrechnungen sind nur statthaft, sofern die Gegenforderung rechtskräftig festgestellt, anerkannt oder unbestritten ist. Ein Zurückbehaltungsrecht steht darüber hinaus dem Auftraggeber nur insoweit zu, als der Grund des Zurückbehaltungsrechts auf dem gleichen Vertragsverhältnis beruht.<br />

5.7 Bei umfangreichem Materialaufwand und langfristigen Arbeiten kann eine angemessene Vorauszahlung verlangt werden, um die Vorfinanzierung des Auftragnehmers zu gewährleisten.</p>


<h3>6. Fertigstellung/Lieferzeit</h3>
<p>Es gilt, sofern verbindlich vereinbart, die jeweils angegebene Fertigstellungs- bzw. Lieferzeit. Soweit die rechtzeitige Lieferung/Leistung ein Fixgeschäft ist, bleiben die gesetzlichen Ansprüche unberührt; dies gilt auch dann, wenn als Folge des Verzugs des Auftragnehmers die weitere Erfüllung der Lieferung/Leistung für den Auftraggeber ohne Interesse ist. Ändert oder erweitert der Auftraggeber den ursprünglich vereinbarten Arbeitsumfang und verzögert sich die Fertigstellung bzw. Lieferung dadurch, haftet der Auftragnehmer dafür nicht. Er nennt dem Auftraggeber unter Angabe der Gründe jedoch unverzüglich einen neuen Fertigstellungs- bzw. Liefertermin. Liegt die Ursache der Nichteinhaltung des Termins in höherer Gewalt oder in Betriebsstörungen, auch in solchen von Vorlieferanten oder Subunternehmern, die der Auftragnehmer nicht verschuldet hat, besteht aufgrund hierdurch bedingter Verzögerungen keine Schadenersatzverpflichtung des Auftragnehmers. Er unterrichtet den Auftraggeber jedoch unverzüglich. Die Fertigstellungs- bzw. Lieferzeit verlängert sich ggf. um die Zeit, die der Auftraggeber mit der Anlieferung von ihm beizustellender notwendiger Teile in Rückstand ist. Der Auftragnehmer ist dabei berechtigt, den Vertrag nach fruchtloser Nachfristsetzung zu kündigen.</p>


<h3>7. Abnahme</h3>
<p>Die Abnahme findet im Betrieb des Auftragnehmers statt, soweit nichts anderes vereinbart ist. Der Auftraggeber kommt mit der Abnahme in Verzug, wenn er innerhalb von zwei Wochen, nachdem ihm die Fertigstellung des Vertragsgegenstandes gemeldet oder die endgültige Rechnung ausgehändigt worden ist, diesen gegen Begleichung der fälligen Rechnung nicht abholt.</p>


<h3>8. Lieferung</h3>
<p>8.1 Die Lieferung des Vertragsgegenstandes erfolgt stets auf Rechnung und Gefahr des Auftraggebers, und zwar ab Betrieb des Auftragnehmers, soweit nicht schriftlich anderes vereinbart ist. Für Leistungen gilt Entsprechendes.<br />

8.2 Wünscht der Auftraggeber Zustellung, so erfolgt diese auf Rechnung und Gefahr des Auftraggebers.</p>


<h3>9. Eigentumsvorbehalt</h3>
<p>9.1 Der gelieferte Vertragsgegenstand bleibt bis zur vollständigen Bezahlung aller zwischen Auftraggeber und Auftragnehmer (bereits) entstandenen Forderungen Eigentum des Auftragnehmers. Im kaufmännischen Verkehr gilt des weiteren, dass die Eigentumsvorbehaltssicherung sich auf den jeweiligen Saldo bezieht, sofern zwischen Auftraggeber und Auftragnehmer eine Kontokorrentvereinbarung besteht. In diesem Fall erstreckt sich der Eigentumsvorbehalt auch auf den kausalen Saldo, sobald der Auftraggeber in Konkurs fällt.<br />

9.2 Der Auftraggeber erklärt sich, wenn er den gelieferten Vertragsgegenstand weiterbearbeitet, damit einverstanden, dass die Bearbeitung stets für den Auftragnehmer erfolgt. Der Auftragnehmer erwirbt Eigentum an dem zu bearbeitenden Vertragsgegenstand.<br />

9.3 Sofern der Auftraggeber Händler ist, ist er zur Weiterveräußerung des Vertragsgegenstandes im normalen Geschäftsgang berechtigt. Für diesen Fall tritt jedoch der Auftraggeber die ihm gegenüber seinen Abnehmern zustehenden Forderungen schon jetzt an den Auftragnehmer ab; zur Einziehung dieser Forderungen bleibt der Auftraggeber berechtigt, solange er nicht gegenüber dem Auftragnehmer in Zahlungsverzug geraten ist. Soweit dies der Fall ist, ist der Auftragnehmer berechtigt, die Weiterveräußerungs- und Einziehungsbefugnis für den Vertragsgegenstand schriftlich zu widerrufen. In diesem Fall ist der Auftraggeber verpflichtet, dem Auftragnehmer alle Informationen, Dokumentationen und sonstigen Unterlagen zu überlassen, aus denen sich ergibt, gegen welche Abnehmer dem Auftragnehmer Forderungen aufgrund des verlängerten Eigentumsvorbehalts zustehen, damit der Auftragnehmer in der Lage ist, diese gegenüber den Abnehmern unmittelbar geltend zu machen.<br />

9.4 Bei Verbindungen oder Vermischungen eines Vertragsgegenstandes entsteht Miteigentum des Auftragnehmers, sofern nicht eine Sache als Hauptsache anzusehen ist. Soweit letzteres der Fall ist, erklärt sich der Auftraggeber schon jetzt damit einverstanden, Sicherungseigentum zugunsten des Auftragnehmers - bezogen auf die Hauptsache - zu vereinbaren. Dieses verwahrt der Auftraggeber unentgeltlich für den Auftragnehmer.für den Auftragnehmer.<br />

9.5 Die Sicherungsübereignung gemäß Ziff. 9.4 sowie die Sicherungsabtretung gemäß 9.3 gelten jeweils in Höhe des Fakturaendbetrages, wie sie zwischen Auftraggeber und Auftragnehmer vereinbart wurde; der Fakturaendbetrag versteht sich einschließlich der gesetzlichen Mehrwertsteuer.<br />

9.6 Wird der unter Vorbehalt stehende Vertragsgegenstand zusammen mit anderen Waren weiterveräußert, so gilt die Bestimmung gemäß Ziff. 9.3. und 9.4. sinngemäß.Übersteigen die dem Auftragnehmer nach den vorstehenden Bestimmungen zustehenden Sicherheiten die zu sichernden Forderungen um mehr als 20%, so ist der Auftragnehmer verpflichtet, auf Verlangen des Auftraggebers überschießende Sicherheiten nach Wahl des Auftragnehmers freizugeben.</p>


<h3>10. Pfandrecht - Verwertung - Standgebühr</h3>
<p>10.1 Dem Auftragnehmer steht ein gesetzliches Pfandrecht an allen Gegenständen des Auftraggebers zu, die mit Wissen und Wollen des Auftraggebers vom Auftragnehmer bearbeitet werden. Das Pfandrecht erstreckt sich auf alle Forderungen des Auftragnehmers, wie sie der Eigentumsvorbehaltsicherung gemäß Ziff. 9.1 entsprechen.<br />

10.2 Kommt der Auftraggeber mit der Zahlung für einen längeren Zeitpunkt als zwei Monate in Verzug, so steht dem Auftragnehmer das Recht zu, nach vorheriger schriftlicher Ankündigung und nach Ablauf einer weiteren Wartezeit von vier Wochen den Vertragsgegenstand durch Versteigerung und bei Vorliegen von Marktpreisen durch freihändigen Verkauf bestmöglich zu verwerten. Ein etwaiger Verwertungserlös steht dem Auftraggeber zu; der Auftragnehmer ist berechtigt, neben seiner Hauptforderung und den angelaufenen Zinsen auch die durch die Verwertung verursachten Kosten in Abzug zu bringen. Ist der Auftragnehmer aus betrieblichen Gründen zur Verwahrung der Pfandsache nicht in der Lage, kann er Ersatz der ihm durch eine anderweitige Lagerung entstandenen Kosten verlangen. Auch bei Verwahrung im eigenen Betrieb entstehende Verwahrkosten werden zu marktüblichen Preisen dem Auftraggeber in Rechnung gestellt.</p>


<h3>11. Sachmangelhaftung bei Instandsetzung/Reparatur</h3>
<p>11.1 Der Auftragnehmer leistet Gewähr für einwandfreie Arbeit und die Verwendung einwandfreien, funktionstüchtigen Materials. Die Sachmangelansprüche des Auftraggebers verjähren innerhalb eines Jahres ab Abnahme des Gegenstandes. Nimmt der Auftraggeber die Sache in Kenntnis eines Sachmangels ab, stehen ihm die Sachmangelansprüche in unten beschriebenen Umfang nur zu, wenn er sich diese bei der Abnahme vorbehält.<br />

11.2 Sofern der Auftraggeber Vollkaufmann ist, bleiben die gesetzlichen Untersuchungs- und Rügepflichten gemäß §§ 377, 378, 381 Abs. 2 HGB unberührt.<br />

11.3 Im Fall der Sachmangelhaftung ist der Auftragnehmer berechtigt und verpflichtet, Mangelbeseitigung auf eigene Kosten durchzuführen. Er ist auch berechtigt, eine Ersatzlieferung zu tätigen. Ist der Auftragnehmer nicht bereit oder nicht in der Lage, insbesondere verzögert sich die durchzuführende Mangelbeseitigung/Ersatzlieferung über angemessene Fristen hinaus oder schlägt sie aus sonstigen Gründen fehl, so ist der Auftraggeber berechtigt, vom Vertrag zurückzutreten (Rücktritt) oder entsprechende Herabsetzung der Vergütung (Minderung) zu begehren.<br />

11.4 Mangelbeseitigungsansprüche hat der Auftraggeber beim Auftragnehmer geltend zu machen. Der Auftragnehmer anerkennt Mangelbeseitigungsarbeiten, die Dritte ausführen, nur dann, wenn er im vorhinein hiermit ausdrücklich einverstanden ist und wenn der Auftragnehmer mit der Durchführung der Sachmangelbeseitung in Verzug geraten ist bzw. wenn ein äußerst dringendes Erfordernis, insbesonders Betriebsunfähigkeit des Gegenstandes an einem mehr als 50 km vom Betriebsort des Auftraggebers entfernten Ort, besteht.<br />

11.5 Die Bearbeitung von einzelnen Vertragsgegenständen geschieht nur im vereinbarten Umfang. Die Sachmangelhaftung erstreckt sich nicht auf eine darüber hinausgehende Funktionstüchtigkeit einzelner Teile. Diese sind nicht Gegenstand der Sachmangelhaftung des Auftragnehmers, soweit nichts anderes schriftlich vereinbart ist. Insbesonders wird ohne gesonderte schriftliche Vereinbarung keine Haltbarkeitsgarantie übernommen.<br />

11.6 Die Schadensersatzhaftung des Auftragnehmers ergibt sich aus der Regelung gemäß Ziff. 13.<br />

11.7 Soweit der Auftragnehmer ein Tuning von Vertragsgegenständen oder eine Bearbeitung von Oldtimer-Vertragsgegenständen übernimmt, beschränkt sich seine Sachmangelhaftung auf die ordnungsgemäße Ausführung dieser Arbeiten. Ein werkvertraglicher Erfolg ist nur dann geschuldet, wenn dies schriftlich zwischen Auftraggeber und Auftragnehmer vereinbart ist.<br />

11.8 Richtet sich der Auftrag auf die Lieferung herzustellender oder zu erzeugender beweglicher Sachen, und ist der Auftraggeber Unternehmer, der den Vertrag in Ausübung seiner selbständigen beruflichen oder gewerblichen Tätigkeit abschließt, oder ist er eine juristische Person des öffentlichen Rechtes oder ein öffentlich � rechtliches Sondervermögen, verjähren Sachmangelansprüche in einem Jahr ab Lieferung. Ist der Auftraggeber Verbraucher, gelten in diesem Fall die gesetzlichen Bestimmungen.<br />

11.9 Wenn ein Mangel nach nicht vom Auftragnehmer durchgeführter Montage / Einbau auftritt, haftet der Auftragnehmer im Rahmen der Sachmangelhaftung nur, wenn Montage oder Einbau der vom Auftragnehmer zuvor bearbeiteten oder verkauften Sache fachkundig und fachgerecht, insbesondere nach Maßgabe und Vorschrift des Herstellers, erfolgte. Die Fachkundigkeit und Fachgerechtigkeit der Montage bzw. des Einbaus muß der Auftraggeber beweisen.</p>


<h3>12. Sachmangelhaftung bei Kauf/Tausch gebrauchter Gegenstände</h3>
<p>Sachmangelansprüche des Käufers verjähren innerhalb eines Jahres ab Lieferung des Kaufgegenstandes an den Käufer. Bei arglistig verschwiegenen Mängeln bleiben weitergehende Ansprüche unberührt. Für die Mangelbeseitigungsabwicklung gilt das gleiche wie unter Ziffer 11.4..</p>


<h3>13. Sonstige Haftung</h3>
<p>13.1 Der Auftragnehmer haftet nicht für Schäden, welche nicht an dem Vertragsgegenstand unmittelbar entstanden sind. Insbesondere haftet der Auftragnehmer nicht für alle Folgeschäden oder entgangenen Gewinn.<br />

13.2 Dies gilt nicht, soweit die Ansprüche des Auftraggebers auf dem Fehlen einer zugesicherten Eigenschaft beruhen. Gleiches gilt, soweit die Ansprüche des Auftraggebers auf einer vorsätzlichen oder grob fahrlässigen Vertragsverletzung des Auftragnehmers beruhen.<br />

13.3 Die Schadensersatzhaftung des Auftragnehmers ist auf den vorhersehbaren Schaden begrenzt, soweit der Auftragnehmer eine wesentliche Vertragspflicht infolge einfacher Fahrlässigkeit verletzt. Im übrigen ist die Haftung für einfache Fahrlässigkeit ausgeschlossen, soweit nicht Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit betroffen sind.<br />

13.4 Eine Haftung gemäß § 1 Produkthaftungsgesetz bleibt unberührt.</p>


<h3>14. Gerichtsstand - Erfüllungsort</h3>
<p>14.1 Sofern nichts anderes schriftlich vereinbart, ist Erfüllungsort der Sitz des Auftragnehmers.<br />

14.2 Ausschließlicher Gerichtsstand für alle sich aus dem Zusammenhang mit diesem Vertrag gegebenen Streitigkeiten ist der Sitz des Auftragnehmers, soweit der Auftraggeber Vollkaufmann ist. Im übrigen bleibt es bei den gesetzlichen Regelungen.<br />

14.3 Der Vertrag unterliegt dem Recht der Bundesrepublik Deutschland; die Geltung des UN-Kaufrechts ist abbedungen.</p>
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                      <Button
                        onClick={() => this.handleClose("classicModal")}
                        color="danger"
                        simple
                      >
                        Schließen
                      </Button>
                    </DialogActions>
                  </Dialog>
                  </div>
    );
  }
}

export default withStyles(agbStyles)(SectionAGB);